import React from 'react';
import { Route, BrowserRouter as Router } from "react-router-dom";
import RedirectPage from "./Pages/RedirectPage/RedirectPage"
import Dashboard from "./Pages/Dashboard/Dashboard"
import './App.css';

function App() {
  return (
    <div className="App">
        <Router>
          <Route exact path="/" component={RedirectPage}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/admin" component={() => <h1>admin</h1>}/>
          <Route path="/zibi" component={() => <h1>zibi</h1>}/>
        </Router>
    </div>
  );
}

export default App;
