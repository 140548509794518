import React from "react";
import { getRedirectedSite } from "../../api/redirectedSite";
import Pikachu from "../../Components/Pikachu/Pikachu";
import "./RedirectPage.scss";

const RedirectPage = () => {
  getRedirectedSite().then((res) => {
    window.location.replace(res);
  });

  return (
    <div className="redirect-page">
      <Pikachu />
      {/* ke */}
    </div>
  );
};

export default RedirectPage;
