import React, { useState } from 'react';
import './Dashboard.scss'
import { changeRedirectedSite } from '../../api/redirectedSite';

const Dashboard = () => {
    const [input, setInput] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        const req = {url: e.target[0].value}
        changeRedirectedSite(req).then(
            res => console.log(res)
        );
    }

    return (
        <div>
            <h1>Dashboard</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" onChange={setInput}/>
                <button type='submit'>send</button>
            </form>
        </div>
    );
};

export default Dashboard;