import {get, post} from './apiContext';

export const getRedirectedSite = async () => {
    const response = await get('redirectedSite');
    return response.data
}

export const changeRedirectedSite = async (url) => {
    const response = await post('setSite', url);
    return response
}